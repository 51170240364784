//== DEFAULTS RAILS
import Rails from "@rails/ujs"
Rails.start()


// //== JQUERY
import $ from 'jquery'
window.$ = window.jQuery = $

// FANCYBOX
import { fancybox } from "components/fancybox";

//== BOOTSTRAP
import { Popper } from '@popperjs/core'
import * as bootstrap from 'bootstrap'

//== FONTAWESOME
import "@fortawesome/fontawesome-free/js/all"

document.addEventListener("DOMContentLoaded", function(event) {
    var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
    var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
        return new bootstrap.Popover(popoverTriggerEl)
    })
});

document.addEventListener("DOMContentLoaded", function(event) {
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl, {
            container: 'body'
        })
    })
});